import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import { State } from '../models/state.model';
import { SpacesModel } from '../models/spaces.model';
import { InventoryModel } from '../models/inventory.model';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class SpacesService {
  apis: IApi<SpacesModel>;

  constructor(http: HttpClient, private appService: AppService) {
    this.apis = new GenericApi<SpacesModel>('studios', http);
  }

  async update(id: any, data: SpacesModel) {
    try {
      const res = await this.apis.update(id, data);
      return res;
    } catch (error) {
      this.appService.toastr.error(error);
      return null;
    }
  }

  async create(data: SpacesModel) {
    try {
      const res = await this.apis.create(data);
      return res;
    } catch (error) {
      this.appService.toastr.error(error);
      return null;
    }
  }
}
