import { Media } from '../models/shootMedia.model';
import { Shoot } from '../models/shoots.model';
import { Deal } from '../models/deal.model';
import { Lead } from '../models/lead.model';
import { Contact } from '../models/contacts.model';
import { Account } from '../models/accounts.model';
import { RealEstate } from '../models/real-estate.model';
import { Injectable } from '@angular/core';
import { ClientRequest } from '../models/client-request.model';
import { Audience } from '../models/audience.model';

@Injectable({
    providedIn:'root'
})

export class CreateholdDataService{

    private _media:Media;
    private _shoot:Shoot;
    private _deal:Deal;
    private _lead:Lead;
    private _contact:Contact;
    private _account:Account;
    private _realEstate:RealEstate;
    private _changeRequest:ClientRequest;
    private _audienceList:Audience;

    get media(){
        return this._media;
    }

    set media(m:Media){
        this._media = m;
    }

    get shoot(){
        return this._shoot;
    }

    set shoot(s:Shoot){
        this._shoot = s;
    }

    get deal(){
        return this._deal;
    }

    set deal(d:Deal){
        this._deal = d;
    }

    get lead(){
        return this._lead;
    }

    set lead(l:Lead){
        this._lead = l;
    }

    get contact(){
        return this._contact;
    }

    set contact(c:Contact){
        this._contact = c;
    }

    get account(){
        return this._account;
    }

    set account(a:Account){
        this._account = a;
    }

    get realEstate(){
        return this._realEstate;
    }

    set realEstate(a:RealEstate){
        this._realEstate = a;
    }

    get changeRequest(){
        return this._changeRequest
    }

    set changeRequest(c:ClientRequest){
        this._changeRequest = c;
    }

    get audience(){
        return this._audienceList
    }

    set audience(a:Audience){
        this._audienceList = a;
    }

}