import { Account } from './accounts.model';

export class Visual {
    id?: number;
    name?: string;
    files:{file:File,isUploading?:boolean}[] = [];
    formatItem?:(value?:any) => any;
    selectedFile?:File;
}

export class VisualData {
    id?: number;
    visualId?: number;
    url?: string;
    type?: string;
    visual?: Visual = new Visual();
    realEstateId: number;
}

export class VisualDataAccount {
    id?: number;
    type?: string;
    accountId?: number;
    url?: string;
    account?: Account = new Account();
    btnDisable?:boolean;
}