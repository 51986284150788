import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';

@Injectable({ providedIn: 'root' })
export class PipelineService {
  pipelines: IApi<any>;

  constructor(http: HttpClient) {
    this.pipelines = new GenericApi<any>('pipelines', http);
  }
}
