import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { BusinessCategoryAddOn } from '../models/business-category-add-on.model';
import { GearModel } from '../models/gear.model';
import { AppService } from './app.service';

@Injectable({
    providedIn: 'root',
})
export class GearService {

    apis: IApi<GearModel> = new GenericApi<GearModel>(
        'gears',
        this._http
    );
    constructor(private _http: HttpClient, public appService: AppService) { }

    async update(id: any, data: GearModel) {
        try {
            const res = await this.apis.update(id, data);
            return res;
        } catch (error) {
            this.appService.toastr.error(error);
            return null;
        }
    }

    async create(data: GearModel) {
        try {
            const res = await this.apis.create(data);
            return res;
        } catch (error) {
            this.appService.toastr.error(error);
            return null;
        }
    }
}
