import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import { TaskCategory, TaskSubTemplate, TaskTemplate } from '../models/task-category.model';

@Injectable({
  providedIn: 'root'
})
export class TaskCategoryService {

  taskCategories:IApi<TaskCategory>;
  taskTemplates:IApi<TaskTemplate>;
  taskSubTemplates:IApi<TaskSubTemplate>;

constructor(http?:HttpClient) {
  this.taskCategories = new GenericApi<TaskCategory>('taskCategories',http);
  this.taskTemplates = new GenericApi<TaskTemplate>('taskTemplates',http);
  this.taskSubTemplates = new GenericApi<TaskTemplate>('taskSubTemplates',http);

 }

}
