import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { EmailService } from 'src/app/services/EmailSmsService.service';

@Component({
  selector: 'app-send-email-sms',
  templateUrl: './send-email-sms.component.html',
  styleUrls: ['./send-email-sms.component.css'],
})
export class SendEmailSmsComponent implements OnInit {
  isLoading: boolean = false;
  form: FormGroup = new FormGroup({
    type: new FormControl(''),
    description: new FormControl('', [Validators.required]),
    email: new FormControl(''),
    phone: new FormControl(''),
  });

  constructor(
    public dialogRef: MatDialogRef<SendEmailSmsComponent>,
    private emailSmsService: EmailService,
    private appService: AppService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      text: string;
      type: string;
      email: string;
      phone: any;
    }
  ) {
    this.form.get('type').setValue(data.type);
  }

  ngOnInit(): void {}

  async send() {
    try {
      if (this.form.invalid) {
        this.form.markAllAsTouched();
        return;
      }
      if (this.data.type == 'email') {
        delete this.form.value.phone;
        this.form.get('email').setValue(this.data.email);
      } else {
        delete this.form.value.email;
        this.form.get('phone').setValue(this.data.phone);
      }
      this.isLoading = true;
      let res = await this.emailSmsService.email.create(this.form.value);
      this.isLoading = false;
      this.appService.toastr.success(`${this.data.text} sent successfully`);
      this.dialogRef.close(true);
    } catch (error) {
      this.isLoading = false;
      this.appService.toastr.error(error);
    }
  }
}
