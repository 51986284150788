import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import { LeadAttachment } from '../models/lead-attachment.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeadAttachmentService {

  leadAttachments:IApi<LeadAttachment>;
  readonly upLoadUrl = environment.apiUrls.api+'/api/leadAttachments';

  constructor(
    http:HttpClient
  ) {
  
    this.leadAttachments = new GenericApi<LeadAttachment>('leadAttachments',http);
  
   }

}
