<section>
  <div class="head-txt">Send {{ data.text }}</div>
  <form class="col row m-0 p-0" [formGroup]="form">
    <div class="col-12 p-0 mt-3">
      <label>Description</label>

      <textarea
        InputUi
        class="w-100"
        rows="6"
        formControlName="description"
      ></textarea>
    </div>

    <div class="center w-100 mt-4">
      <button class="btn3" (click)="send()">Send {{ data.text }}</button>
      <button class="btn1 ml-3" (click)="this.dialogRef.close()">Cancel</button>
    </div>
  </form>
</section>
