import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import { VisualData, Visual, VisualDataAccount } from '../models/visual.data.model';

@Injectable({
    providedIn: "root"
})

export class VisualDataService {

    visualData: IApi<VisualData>;
    accountAttachments: IApi<VisualDataAccount>;
    visuals: IApi<Visual>;

    constructor(http: HttpClient) {

        this.visuals = new GenericApi<Visual>('visuals', http);
        this.visualData = new GenericApi<VisualData>('realEstateVisuals', http);
        this.accountAttachments = new GenericApi<VisualDataAccount>('accountAttachments', http);
    }

}