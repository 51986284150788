import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Audience } from 'src/app/models/audience.model';
import { AppService } from 'src/app/services/app.service';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-export-contact',
  templateUrl: './export-contact.component.html',
  styleUrls: ['./export-contact.component.scss'],
})
export class ExportContactComponent implements OnInit {
  loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<ExportContactComponent>,
    private _contactService: ContactService,
    public appService: AppService
  ) { }

  ngOnInit() { }

  exportContacts(a: Audience[]) {
    const audienceIds = a.filter((i) => i.selected).map((i) => i.id);
    if (!audienceIds.length) {
      return this.appService.toastr.error('Please select any audiences.');
    }
    this.loading = true;
    // this._contactService
    //   .exportContacts(audienceIds)
    //   .then((d) => {
    //     this.dialogRef.close(true);
    //   })
    //   .catch((err) => {
    //     this.loading = false;
    //     this.appService.toastr.show(err);
    //   });
    this.appService
      .exportExcel({ audienceIds }, 'contacts/create/excel')
      .then((d) => {
        if (d) {
          const blob = new Blob([d], {
            type: 'application/xlsx',
          });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          const now = new Date();
          const dateString = now.toISOString().split('T')[0];
          const timeString = now.toTimeString().split(' ')[0].replace(/:/g, '');
          const filename = `${dateString}_${timeString}.xlsx`;
          a.download = filename;
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        }
        this.dialogRef.close(true);
      })
      .catch((err) => {
        this.loading = false;
        this.appService.toastr.show(err);
      });
  }

  selectAllContact(item: any) {
    if (item) {
      item.forEach((d) => {
        d.selected = true
      })
    }
  }

}
