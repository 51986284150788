import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})

export class DealContractService {

    dealContracts: IApi<any>;
   readonly upLoadUrl = environment.apiUrls.api+'/api/dealContracts';

  constructor(private http: HttpClient) {
    this.dealContracts = new GenericApi<any>('dealContracts', http)
  }
    
}