import { Admin } from './lead.model';
import { MediaCategory } from './mediaCategory.model';
import { MediaFinalCategory } from './MediaFinalCategory.model';
import { MediaSubCategory } from './mediaSubCategory.model';
import { Shoot, shootSelectedSubCategory } from './shoots.model';

export class Media {
    id?: number;
    name?: string;
    mediaType?: string;
    cameraStyle?: string;
    imageStyle?: string;
    lighting?: string;
    focus?: string;
    rawDataLink?: string;
    finalDataLink?: string;
    shootId?: number;
    mediaNumber?: number;
    color?: string;
    createdBy?: number;
    editedBy?: number;
    creationLocation?: string;
    createdDate?: string;
    editingDueDate?: string;
    subLocation?: string;
    btnDisable?: boolean;
    shoot?: Shoot = new Shoot();
    creator?: Admin = new Admin();
    editor?: Admin = new Admin();
    status?: string;
    shootSelectedSubCategories?: shootSelectedSubCategory[] = [];
    shootSelectedSubCategoryId?: number;
    category?: string;
    priority?: number;
    mediaCategory?: MediaCategory;
    mediaSubCategory?: MediaSubCategory;
    mediaFinalCategory?: MediaFinalCategory;
    mediaCategoryId?: MediaCategory;
    mediaSubCategoryId?: MediaSubCategory;
    mediaFinalCategoryId?: MediaFinalCategory;

}

