export class GearModel {
    type?: string;
    id?: number;
    title?: string;
    gearName?: string;
    model?: string;
    gearType?: string;
    media?: string;
    inputs?: string;
    description?: string;
    life?: string;
    businessCategoryServiceIds?: number[];
  gearBusinessCategoryServices?: any;

}

export enum GEAR_TYPE{
    EQUIPMENT = 'equipment',
    SOFTWERE = 'software',
}
