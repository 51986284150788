import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { AppService } from './app.service';
import { Coupon } from 'src/app/models/coupon.model';

@Injectable({
  providedIn: 'root',
})
export class CouponsService {
  apis: IApi<Coupon> = new GenericApi<Coupon>('coupons', this._http);
  constructor(private _http: HttpClient, public appService: AppService) { }


  async update(id: any, data: Coupon) {
    try {
      const res = await this.apis.update(id, data);
      return res;
    } catch (error) {
      this.appService.toastr.error(error);
      return null;
    }
  }

  async create(data: Coupon) {
    try {
      const res = await this.apis.create(data);
      return res;
    } catch (error) {
      this.appService.toastr.error(error);
      return null;
    }
  }

}
