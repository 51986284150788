import { Lead } from "./lead.model";

export class LeadAttachment {
    id?:number;
    url?:string;
    name?:string;
    leadId?:number;
    lead?:Lead = {};
    btnDisable?:boolean;
}
