import { BusinessCategory } from './businessCategory.model';
import { GearModel } from './gear.model';
import { InventoryModel } from './inventory.model';

export class BusinessCategoryServiceModel {
  access?: string;
  businessCategory?: BusinessCategory;
  businessCategoryId?: any;
  confirmPageMessage?: string;
  description?: string;
  durationInMinutes?: number;
  id?: number;
  name?: string;
  position?: number;
  price?: number;
  formTypeIds?: [];
  gearIds?: number[];
  inventoryIds?: number[];
  resourceIds?: [];
  businessCategoryServiceGears?: { id?: number; gear: GearModel }[];
  businessCategoryServiceInventories?: { id?: number; inventory: InventoryModel }[];
  businessCategoryServiceStudios?: any;
  studioIds?: any;
}
