export class TaskCategory {
    id?: number;
    name?: string;
    description?: string;
    thumbnail?: string;
    taskTemplates?:TaskTemplate[];
    isLoading?:boolean;
    
}
export class TaskSubTemplate{
    id?: number;
    title?: string;
    taskTemplateId?:number;
    selected?:boolean;
}

export class TaskTemplate {
    id?: number;
    title?: string;
    taskSubTemplates?:TaskSubTemplate[] = [];
    taskCategoryId?:number;
}
