import { Injectable } from '@angular/core';
import { Message } from './message.model';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  msgs = [];
  temps = [];

  constructor() {}

  error(msg: string, bg?: string, color?: string) {
    let error = new Message();
    error.msg = msg;
    error.sucess = false;
    error.bg = bg;
    error.color = color;
    if (!bg) {
      error.bg = 'red';
    }
    if (!color) {
      error.color = 'white';
    }
    this.msgs.pop();
    this.msgs.splice(0, 0, error);
  }

  success(msg: string, bg?: string, color?: string) {
    let success = new Message();
    success.msg = msg;
    success.sucess = true;
    success.bg = bg;
    success.color = color;
    if (!bg) {
      success.bg = 'green';
    }
    if (!color) {
      success.color = 'white';
    }
    this.msgs.pop();
    this.msgs.splice(0, 0, success);
  }

  openDialog(temp, style, disableClose?: boolean) {
    if (temp) {
      this.temps.push({
        temp: temp,
        style: style,
        disableClose: disableClose ? disableClose : false,
      });
    }
  }

  close(matchTemp) {
    if (this.temps.length && matchTemp) {
      let i = this.temps.findIndex((e) => e.temp == matchTemp);
      this.temps.splice(i, 1);
    }
  }

  closeAll() {
    this.temps = [];
  }
}
