<select-audiences hidden #audiencesSearch></select-audiences>

<div class="export-mdl">
  <div class="row">
    <div class="col-10">
      <font-25>Choose the audience groups for exporting contacts.</font-25>
    </div>
    <div class="col-2 cross">
      <img
        (click)="dialogRef.close(null)"
        width="30px"
        src="assets/images/cancel-button.svg"
        alt=""
      />
    </div>
  </div>
  <div class="mt-4">
    <div>
      <div
        class="cross-chip purple mr-1 mb-1"
        (click)="item.selected = !item.selected"
        [class.selected]="item?.selected"
        *ngFor="let item of audiencesSearch?.audiences?.items"
      >
        {{ item?.name | titlecase }}
      </div>
      <loading *ngIf="audiencesSearch?.audiences?.isLoading"></loading>
    </div>
  </div>
  <div class="mt-3 text-right">
    <button
      class="theme-btn orange mr-2"
      [disabled]="loading"
      (click)="selectAllContact(audiencesSearch?.audiences?.items)"
    >
      Select all
    </button>
    <button
      class="theme-btn orange"
      [disabled]="loading"
      (click)="exportContacts(audiencesSearch?.audiences?.items)"
    >
      Export
    </button>
  </div>
</div>
