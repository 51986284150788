export class Coupon {
  code?: string;
  endDate?: string;
  name?: string;
  startDate?: string;
  status?: string;
  type?: string;
  id?: number;
  discount?: number;
  totalUsage?: number;
  totalUsageLimit?: number;
  usagePerUser?: number;
}
