import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { EmailSmsModel } from '../models/email-sms.model';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  email: IApi<EmailSmsModel>;
  constructor(private http: HttpClient) {
    this.email = new GenericApi<EmailSmsModel>('emailSends', http);
  }
}
