import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { RealEstate } from '../models/real-estate.model';
import { Contact } from '../models/contacts.model';
@Injectable({
  providedIn: 'root'
})
export class RealEstateService {

  estate: IApi<RealEstate>;
  contacts: IApi<Contact>;
  constructor(private http: HttpClient) {
    this.estate = new GenericApi<RealEstate>('realEstates', http);
    this.contacts = new GenericApi<Contact>('contacts', http)
  }

}
