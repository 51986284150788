import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { Media } from '../models/shootMedia.model';

@Injectable({
    providedIn: 'root'
})

export class MediaService {

    medias: IApi<Media>;
    constructor(private http: HttpClient) {
        this.medias = new GenericApi<Media>('shootMedias', http)
    }

}
