import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { ClientDashboard } from '../models/client-dashboard.model';
import { ClientRequest } from '../models/client-request.model';

@Injectable({
  providedIn: 'root'
})
export class ClientDashboardService {
  dashboard: IApi<ClientDashboard>;
  changeRequests: IApi<ClientRequest>;
  constructor(private http: HttpClient) {
    this.dashboard = new GenericApi<ClientDashboard>('accounts/dashboard/client', http)
    this.changeRequests = new GenericApi<ClientRequest>('changeRequests', http);
  }

}
