import { BusinessCategoryService } from "../services/business-categories.service";
import { BusinessCategoryServiceModel } from "./business-category-service.model";

export class SpacesModel {
    name: string;
    location: string;
    space: string;
    sundayTimeSlot: string;
    businessCategoryServiceId: number;
    businessCategoryService?: BusinessCategoryServiceModel;
}