import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { BusinessCategoryServiceModel } from '../models/business-category-service.model';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class BusinessCategoryServicesService {
  apis: IApi<BusinessCategoryServiceModel> =
    new GenericApi<BusinessCategoryServiceModel>(
      'businessCategoryServices',
      this._http
    );
  constructor(private _http: HttpClient, public appService: AppService) {}

  async update(id: any, data: BusinessCategoryServiceModel) {
    try {
      const res = await this.apis.update(id, data);
      return res;
    } catch (error) {
      this.appService.toastr.error(error);
      return null;
    }
  }

  async create(data: BusinessCategoryServiceModel) {
    try {
      const res = await this.apis.create(data);
      return res;
    } catch (error) {
      this.appService.toastr.error(error);
      return null;
    }
  }

  async remove(id: any) {
    try {
      const res = await this.apis.remove(id);
      return res || true;
    } catch (error) {
      this.appService.toastr.error(error);
      return null;
    }
  }
}
