import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { InventoryModel } from '../models/inventory.model';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  apis: IApi<InventoryModel> = new GenericApi<InventoryModel>(
    'inventories',
    this._http
  );
  constructor(private _http: HttpClient, public appService: AppService) { }

  async update(id: any, data: InventoryModel) {
    try {
      const res = await this.apis.update(id, data);
      return res;
    } catch (error) {
      this.appService.toastr.error(error);
      return null;
    }
  }

  async create(data: InventoryModel) {
    try {
      const res = await this.apis.create(data);
      return res;
    } catch (error) {
      this.appService.toastr.error(error);
      return null;
    }
  }

}
